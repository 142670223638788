<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-text-field
      v-model="form.firstName"
      label="Firstname"
      required
    ></v-text-field>

    <v-text-field
      v-model="form.lastName"
      label="Lastname"
      required
    ></v-text-field>

    <v-text-field
      v-model="form.email"
      label="E-mail"
      type="email"
      required
    ></v-text-field>

    <v-text-field
      v-model="form.username"
      label="Username"
      required
    ></v-text-field>

    <v-text-field
      v-model="form.password"
      label="Password"
      type="password"
      required
    ></v-text-field>

    <v-text-field
      v-model="form.orgName"
      label="Organization"
      required
    ></v-text-field>

    <v-text-field
      v-model="form.mobile"
      label="Mobile"
      type="tel"
      required
    ></v-text-field>

    <v-btn
      :disabled="!valid"
      color="success"
      class="mr-4"
      @click="handleSubmitForm"
    >
      Submit
    </v-btn>

    <v-btn
      color="error"
      class="mr-4"
      @click="reset"
    >
      Reset Form
    </v-btn>
  </v-form>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'Register',
  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        email: null,
        username: null,
        password: null,
        orgName: null,
        mobile: null
      }
    }
  },
  methods: {
    handleSubmitForm () {
      api.post('/api/user',
        this.form).then((response) => {
        // console.log(response.status)
        const msg = this.globalToastFn(`Update: ${this.endpoint.name}`, response.statusText)
        if (response.status === 200) {
          this.$toast.success(msg)
          this.$emit('updated')
        } else {
          this.$toast.error(msg)
        }
        this.$router.push({ name: 'End-Point' })
      })
    }
  }
}
</script>
